.Options-Accounts-1,
.Options-Accounts-1-Float {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-sizing: border-box;
}

.Options-Accounts-1 {
    height: 60px;
    border-radius: 10px;
}

.Options-Accounts-1-Float {
    height: 100%;
    border-radius: 6px;
}

.Options-Accounts-2,
.Options-Accounts-2-Float {
    height: inherit;
    display: flex;
    align-items: center;
}

.Options-Accounts-2 {
    width: inherit;
    justify-content: space-around;
}

.Options-Accounts-2-Float {
    width: 90px;
    justify-content: center;
}

.Options-MyAccount,
.Options-MyAccount-Float {
    display: grid;    
    grid-template-areas:
        "myaccount-nickname myaccount-profile"
        "myaccount-access myaccount-profile";
}

.Options-MyAccount {
    height: 48px;
    grid-template-columns: 1fr 48px;
    grid-template-rows: 1fr 1fr;
}

.Options-MyAccount-Float {
    height: 32px;
    grid-template-columns: 1fr 32px;
    grid-template-rows: 1fr 1fr;
}

.Options-MyAccount-Nickname,
.Options-MyAccount-Nickname-Float,
.Options-MyAccount-Profile,
.Options-MyAccount-Profile-Float,
.Options-MyAccount-Access,
.Options-MyAccount-Access-Float {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
}

.Options-MyAccount-Nickname,
.Options-MyAccount-Nickname-Float {
    grid-area: myaccount-nickname;
    height: 12px;
}

.Options-MyAccount-Nickname-Float {
    margin-right: 3px;
    font-size: 14px;
}

.Options-MyAccount-Profile,
.Options-MyAccount-Profile-Float {
    grid-area: myaccount-profile;
    font-weight: bold;
    cursor: pointer;
}

.Options-MyAccount-Profile {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    font-size: 20px;
}

.Options-MyAccount-Profile-Float {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    font-size: 15px;
}

.Options-MyAccount-Profile,
.Options-MyAccount-Profile-Float,
.Options-MyAccount-Access,
.Options-MyAccount-Access-Float {
    background-color: #a0a0a0;
    color: white;
}

.Options-MyAccount-Profile *:hover,
.Options-MyAccount-Profile-Float *:hover {
    opacity: 0.9;
}

.Options-MyAccount-Access,
.Options-MyAccount-Access-Float {
    grid-area: myaccount-access;
}

.Options-MyAccount-Access {
    height: 13px;
    padding: 1px 5px 1px 5px;
    border-radius: 13px;
    font-size: 11px;
    font-weight: 500;
}

.Options-MyAccount-Access-Float {
    height: 11px;
    padding: 1px 4px 1px 4px;
    margin-right: 3px;
    border-radius: 11px;
    font-size: 10px;
    font-weight: 400;
}

