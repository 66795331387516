.List {
    max-height: 100vh;
    grid-area: List;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    box-sizing: border-box;
    background-color: #a4c0ea;
}

.List-Result {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    background-color: white;
}

.List-2-Result {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: auto;
}

