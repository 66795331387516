.Options-Versatile {
    width: 100%;
    border-radius: 10px;
    overflow-y: auto;
    background-color: rgb(240, 240, 240);
}

@media (orientation: portrait) {
    .Options-Versatile {
        height: 100%;
    }
}

@media (orientation: landscape) {
    .Options-Versatile {
        height: 100%;
    }
}

