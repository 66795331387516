.Options,
.Options-Float {
    grid-area: Options;
    max-height: 100vh;
    padding: 5px;
    box-sizing: border-box;
    background-color: #a4c0ea;
}

@media (orientation: portrait) {
    .Options,
    .Options-Float {
        width: 100%;
    }
}

@media (orientation: landscape) {
    .Options {
        width: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    .Options-Float {
        width: 140px;
        height: 50px;
        position: absolute;
        right: 0;
        border-radius: 0 0 0 10px;
    }
}

