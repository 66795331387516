.Options-Draw,
.Options-Draw-LeftSide,
.Options-Draw-RightSide {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.Options-Draw {
    width: 100%;
    height: 140px;
    border-radius: 10px;
    background-color: white;
}

.Options-Draw-LeftSide,
.Options-Draw-RightSide {
    padding: 8px;
    flex-direction: column;
    box-sizing: border-box;
}

.Options-Draw-LeftSide {
    border-bottom-left-radius: 10px;
}

.Options-Draw-RightSide {
    border-bottom-right-radius: 10px;
}

