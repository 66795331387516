.App {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    display: grid;
}

@media (orientation: portrait) {
    .App {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 360px;
        grid-template-areas: "Map Map" "List Options";
    }
}

@media (orientation: landscape) {
    .App {
        min-height: 400px;
        grid-template-columns: 200px 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "List Map Options";
    }
}

