.EmptyMap,
.GoogleMaps {
    max-height: 100vh;
    grid-area: Map;
    background-color: rgb(225, 225, 225);
}

.EmptyMap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 35px;
}

